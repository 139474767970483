var initPage =  (function () {
	
	// SLICK
	$('.init-slider').slick({
		autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        arrows: false,
        dots: true,
        useCSS: false
	});

})();

// CONTACT FORM
var input = $('.form-group input');

input.focusin(function() {
	$(this).parent().addClass('focus');
});

input.focusout(function() {
	$(this).parent().removeClass('focus');
});

input.keyup(function(event) {
	if ($(this).val() !== '') {
		$(this).parent().addClass('active');
	} else {
		$(this).parent().removeClass('active');
	}
});

// CONTACT FORM VALIDATION
$("#formContact").validate({
    focusCleanup: true,
    focusInvalid: true,
    onkeyup: false,
    onfocusout: false,

    highlight: function(element) {
        $(element).parent().addClass("val-error");
    },
    unhighlight: function(element) {
        $(element).parent().removeClass("val-error");
    },
    errorPlacement: function(error, element) { }
    
});

$('#formContact').submit(function (event) {
    event.preventDefault();

    if ($("#formContact").valid()) {
        
        $.ajax({
            type: 'POST',
            url: 'sendform.php',
            data: $('#formContact').serialize(),

            beforeSend: function() {
                $('.contact-form').fadeOut('fast');
                $('.loader').delay(200).removeClass('is-hidden');
            },

            success: function () {
                $('.loader').addClass('is-hidden');
                $('.contact-thank').delay(600).fadeIn('fast');
            }
        });

    }
});